<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "CustomObjects.Kanban",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <router-link
        :to="{
          path: '/ProjectDesign/Kanban/List',
        }"
        class="btn btn-action-list-page"
      >
        <i class="bi bi-list"></i>
        {{
          $t("KanbanView.AllData", {}, { locale: this.$store.state.activeLang })
        }}
      </router-link>
      <router-link
        :to="{
          name: 'CustomObjectNewKanban',
          params: { customObjectId: this.$route.params.customObjectId },
        }"
        class="btn btn-success btn-action-new-page"
      >
        <i class="bi bi-plus"></i>
        {{
          $t(
            "KanbanView.NewButton",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </router-link>
    </div>
  </div>
  <Grid :settings="gridSettings" :columns="gridColumns" />
</template>
<script>
export default {
  name: "CustomObjectKanban",
  data() {
    return {
      gridSettings: {
        action: "CustomObjectKanban",
        requestUrl: String.format("/Lcdp-KanbanViewList"),
        requestUrlRouteParamNames: [{ key: "q", value: "customObjectId" }],
        routeRequiredParameters: [
          {
            key: "kanbanId",
            value: "publicId",
            isRouteParameter: false,
          },
          {
            key: "customObjectId",
            value: "customObjectId",
            isRouteParameter: true,
          },
        ],

        serialNoLink: `#/CustomObject/EditKanban/__CUSTOMOBJECTPUBLICID__&kanbanId=`,

        allowSearchPanel: false,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: true,
        deleteActionUrl: "/Lcdp-KanbanViewDelete",
        autoOrderProcess: true,

        isGatewayRequest: true,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: true,
            routeObj: {
              name: "CustomObjectEditKanban",
              params: {
                customObjectId: "",
                kanbanId: "",
              },
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "KanbanView.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 100,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "KanbanView.KanbanColumnField",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "kanbanColumnFieldName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "KanbanView.HeaderField",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "headerFieldName",
          type: "string",
          visible: true,
          width: 150,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "KanbanView.Description",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "descriptionFieldName",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.CreatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedBy",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedByName",
          type: "string",
          visible: false,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.UpdatedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "updatedAt",
          type: "datetime",
          visible: false,
          width: 0,
          template: "",
          format: this.$store.getters._dateTimeFormat,
          textAlign: "",
        },
      ],
    };
  },
  methods: {},
  mounted() {},
};
</script>
